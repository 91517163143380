.hero {
    min-height: 400px;
}

.hero_col {
    display: none;
}

@media (min-width: 992px) {
    .hero_col {
        display: flex;
        background-color: #C2A799; 
        justify-content: center; 
        align-items: center;
    }
}

@media (min-width: 1200px) {
    .hero_col {
        display: flex;
        background-color: #C2A799; 
        justify-content: center; 
        align-items: center;
    }
}

@media (min-width: 1400px) {
    .hero_col {
        display: flex;
        background-color: #C2A799; 
        justify-content: center; 
        align-items: center;
    }
}