.header_gl {
    height: 350px;
    width: 100%;
    background-color: #DDDBD1;
    padding-top: 30px;
    display: flex;
    justify-content: center;
}

.card_gl {
    position: relative; 
    top: -240px;
    background-color: #fff;
    padding-inline: 40px;
}

.card_gl h1 {
    text-align: center;
}

.card_gl p,
.card_gl ol > li {
    text-align: justify;
    font-family: "GothamLight", sans-serif;
    font-weight: 500;
}