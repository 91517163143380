.services {
    text-align: center;
    padding-block: 30px;
}

.services > h1,h2,h3,h4,h6,p {
    color: #000000;
}

.services h2 {
    margin-bottom: 20px;
}

.row_services {
    margin-bottom: 14px;
}

.service_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-height: 170px;
    background-color: transparent;
}

.card_title {
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: #FFF;
}

.service_card:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

.service_card:hover .card_title {
    color: #FFF;
}

.service_card:hover p, button {
    opacity: 1;
}

.service_button {
    background-color: #BA9784;
    width: 70%;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .service_card {
       min-height: 300px;
    }
}
  
 @media (min-width: 992px) {
    .service_card {
       min-height: 300px;
    }
}
  
 @media (min-width: 1200px) {
    .service_card {
       min-height: 380px;
    }
}
  
 @media (min-width: 1400px) {
    .service_card {
       min-height: 380px;
    }
}