.registry_event {
    background-color: #BA9784;
    padding-block: 40px;
    padding-inline: 10px;
    text-align: center;
}

.registry_event p {
    font-family: "GothamLight", sans-serif;
    font-weight: 400;
    font-size: .95rem;
}

.carousel_events {
    display: block;
}

.row_events {
    display: none;
}

.service_button {
    background-color: #BA9784;
    width: 70%;
    margin-bottom: 10px;
    height: 38px;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Gotham', sans-serif !important;
    padding-top: 10px;
    font-weight: 500;
    border-radius: 2px;
}

.card_event {
    width: 90%;
    min-height: 290px;
    margin-inline: 5%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(10,10,10,0.5);
}

.registry_event_btn {
    margin-top: 10px;
    color: #FFF !important;
    border-color: #FFF !important;
    width: 70%;
}

@media (min-width: 576px) {
    .carousel_events {
        display: block;
    }
    
    .row_events {
        display: none;
    }

    .card_event {
        width: 90%;
        min-height: 290px;
        margin-inline: 5%;
    }
}

@media (min-width: 768px) {
    .registry_event {
        padding-inline: 0;
    }

    .carousel_events {
        display: block;
    }
    
    .row_events {
        display: none;
    }

    .card_event {
        width: 70%;
        min-height: 290px;
        margin-inline: 15%;
    }

    .registry_event_btn {
        margin-top: 15px;
        width: 60%;
    }

    .registry_event p {
        font-size: 1.05rem;
    }
}
  
@media (min-width: 992px) {
    .registry_event {
        padding-inline: 0;
    }

    .row_events {
        display: flex;
    }

    .carousel_events {
        display: none;
    }

    .card_event {
        width: 100%;
        min-height: 300px;
        margin-inline: 0;
    }

    .registry_event_btn {
        margin-top: 20px;
        width: 45%;
    }

    .registry_event p {
        font-size: 1.05rem;
    }
}
  
@media (min-width: 1200px) {
    .registry_event {
        padding-inline: 0;
    }

    .row_events {
        display: flex;
    }

    .carousel_events {
        display: none;
    }

    .card_event {
        width: 100%;
        min-height: 380px;
        margin-inline: 0;
    }

    .registry_event_btn {
        margin-top: 20px;
        width: 40%;
    }

    .registry_event p {
        font-size: 1.05rem;
    }
}
  
@media (min-width: 1400px) {
    .registry_event {
        padding-inline: 0;
    }

    .row_events {
        display: flex;
    }

    .carousel_events {
        display: none;
    }

    .card_event {
        width: 100%;
        min-height: 380px;
        margin-inline: 0;
    }

    .registry_event_btn {
        margin-top: 20px;
        width: 40%;
    }

    .registry_event p {
        font-size: 1.05rem;
    }
}