.contain {
    background-color: "FFFFFF";
    padding: 0;
}

.subcontain {
    background-color: #866F5E;
    border-radius: 0;
    padding: 40px;
}

.subcontain h2 {
    color: #FFFFFF;
    font-size: 1.4rem;
}

.subcontain h2 > span {
    font-family: "AthelasItalic";
    font-size: 1.6rem;
}

.subscribe {
    text-align: center;
}

.form {
    display: flex;
    align-items: center; 
    justify-content: center;
    flex-wrap: wrap;
}

.form_input {
    width: 100%;
    margin-inline: 10px;
    margin-bottom: 10px;
}

.form_button {
    width: 100%;
    margin-bottom: 10px;
    margin-inline: 10px;
}

@media (min-width: 576px) {
    .contain {
        padding: 0;
    }

    .subcontain {
        border-radius: 0;
    }

    .form_input {
        width: 100%;
        margin-inline: 10px;
        margin-bottom: 10px;
    }
    
    .form_button {
        width: 100%;
        margin-bottom: 10px;
        margin-inline: 10px;
    }
}

@media (min-width: 768px) {
    .contain {
        padding: 30px;
    }

    .subcontain {
        border-radius: 10px;
    }

    .subscribe {
        text-align: left;
    }

    .form {
        flex-wrap: nowrap;
    }

    .form_input {
        width: 50%;
        margin-left: 0;
        margin-right: 5px;
    }
    
    .form_button {
        width: 50%;
        margin-left: 5px;
        margin-right: 0;
    }
}

@media (min-width: 992px) {
    .contain {
        padding: 30px;
    }

    .subcontain {
        border-radius: 10px;
        padding: 40px 80px;
    }

    .subscribe {
        text-align: left;
    }

    .form {
        flex-wrap: nowrap;
    }

    .form_input {
        width: 50%;
        margin-left: 0;
        margin-right: 5px;
    }
    
    .form_button {
        width: 50%;
        margin-left: 5px;
        margin-right: 0;
    }
}

@media (min-width: 1200px) {
    .contain {
        padding: 40px;
    }

    .subcontain {
        border-radius: 10px;
        padding: 40px 80px;
    }

    .subscribe {
        text-align: left;
    }

    .form {
        flex-wrap: nowrap;
    }

    .form_input {
        width: 50%;
        margin-left: 0;
        margin-right: 5px;
    }
    
    .form_button {
        width: 50%;
        margin-left: 5px;
        margin-right: 0;
    }
}

@media (min-width: 1400px) {
    .contain {
        padding: 40px;
    }

    .subcontain {
        border-radius: 10px;
        padding: 40px 80px;
    }

    .subscribe {
        text-align: left;
    }

    .form {
        flex-wrap: nowrap;
    }

    .form_input {
        width: 50%;
        margin-left: 0;
        margin-right: 5px;
    }
    
    .form_button {
        width: 50%;
        margin-left: 5px;
        margin-right: 0;
    }
}