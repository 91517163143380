.cart_detail {
    background-color: #FFFFFF;
}

.absolute_div {
    position: absolute; 
    top: 104; 
    left: 0; 
    right: 0; 
    background-color: #DDDBD1; 
    height: 60vh; 
    z-index: 1
}

.relative_div {
    z-index: 2; 
    position: relative
}

.cart_contact_col {
    background-color: #FFFFFF;
    padding: 1rem;
    text-align: left;
}

.cart_products_col {
    padding: 1rem;
    text-align: left;
}

.cart_logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.cart_container_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart_container_btn button {
    margin-top: 10px;
    width: 50%;
}

.payment_button {
    width: 100%;
    color: #FFF;
    background-color: rgb(10, 151, 0);
    /* padding: 10px;
    margin-block-start: 15px; */
    border: none;
    border-radius: 5px;
}

.payment_button:disabled,
.payment_button:hover:disabled {
    width: 100%;
    color: #FFF;
    background-color: rgb(125, 135, 125);
    /* padding: 10px;
    margin-block-start: 15px; */
    border: none;
    border-radius: 5px;
}

.payment_button:hover {
    background-color: rgb(13, 113, 6);
    color: #FFFFFF !important;
    cursor: pointer;
}

.shopping_radio_div:hover {
    cursor: pointer;
}

@media (min-width: 576px) {
    .cart_products_col,
    .cart_contact_col {
        padding: 1rem;
    }
}

@media (min-width: 768px) {
    .cart_products_col,
    .cart_contact_col {
        padding: 2rem 4rem;
    }
}

@media (min-width: 992px) {
    .cart_products_col,
    .cart_contact_col {
        padding: 2rem;
    }
}

@media (min-width: 1200px) {
    .cart_products_col,
    .cart_contact_col {
        padding: 2rem 4rem;
    }
}

@media (min-width: 1400px) {
    .cart_products_col,
    .cart_contact_col {
        padding: 2rem 4rem;
    }
}