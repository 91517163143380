.select_locale {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 32px;
    min-width: 300px;
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 3px;
    padding-inline: 8px;
    margin-bottom: 24px;
}

.flags p {
    text-align: left;
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 14px;
    color: #3a2d32;
    font-weight: 400;
}

.flags span {
    margin-right: 8px;
}

.small_hero {
    display: block;
    background-image: url(../../assets/images/background/hero-background.webp);
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh;
    width: calc(100vw);
}

.row_hero {
    background-color: #FFFFFF;
    padding: 24px;
    height: max-content;
}

.row_hero img {
    margin-bottom: 20px;
}

@media (min-width: 576px) {
    .small_hero {
        display: block;
    }

    .row_hero {
        background-color: #FFFFFF;
    }
}

@media (min-width: 768px) {
    .small_hero {
        display: block;
    }

    .row_hero {
        background-color: #FFFFFF;
    }
}

@media (min-width: 992px) {
    .small_hero {
        display: none;
    }

    .row_hero {
        background-image: url(../../assets/images/background/hero-background.webp);
        background-repeat: no-repeat;
        background-size: cover;
        height: 45vh;
    }
}
   
@media (min-width: 1200px) {
    .small_hero {
        display: none;
    }

    .row_hero {
        background-image: url(../../assets/images/background/hero-background.webp);
        background-repeat: no-repeat;
        background-size: cover;
        height: 45vh;
    }
}
   
@media (min-width: 1400px) {
    .small_hero {
        display: none;
    }

    .row_hero {
        background-image: url(../../assets/images/background/hero-background.webp);
        background-repeat: no-repeat;
        background-size: cover;
        height: 45vh;
    }
}