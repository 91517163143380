.content { 
    height: 40px;
    background-color: #C1A698;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content p {
    color: #FFFFFF;
    margin: 0 !important;
    font-size: 0.7rem;
}

@media (min-width: 576px) {
    .content p {
        font-size: 0.7rem;
    }
}

@media (min-width: 768px) {
    .content p {
        font-size: 0.9rem;
    }
}

@media (min-width: 992px) {
    .content p {
        font-size: 0.9rem;
    }
}

@media (min-width: 1200px) {
    .content p {
        font-size: 0.9rem;
    }
}

@media (min-width: 1400px) {
    .content p {
        font-size: 0.9rem;
    }
}