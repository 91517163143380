.container {
    width: 100%;
    padding-right: .75rem;
    padding-left: .75rem;
    margin-right: auto !important;
    margin-left: auto !important;
 }
 
@media (min-width: 576px) {
   .container {
      max-width: 540px;
   }
}
 
@media (min-width: 768px) {
   .container {
      max-width: 720px;
   }
}
 
@media (min-width: 992px) {
   .container {
      max-width: 960px;
   }
 }
 
@media (min-width: 1200px) {
   .container {
      max-width: 1140px;
   }
 }
 
@media (min-width: 1400px) {
   .container {
      max-width: 1320px;
   }
}

.p-0 {
    padding: 0;
}

.p-1 {
   padding: 0.25rem;
}

.p-2 {
   padding: 0.5rem;
}

.p-3 {
   padding: 1rem;
}

.p-4 {
   padding: 1.5rem;
}

.p-5 {
   padding: 2.5rem;
}

.pl-1 {
   padding-left: 0.25rem;
}

.pl-2 {
   padding-left: 0.5rem;
}

.pl-3 {
   padding-left: 1rem;
}

.pl-4 {
   padding-left: 1.5rem;
}

.pl-5 {
   padding-left: 2.5rem;
}

.pr-1 {
   padding-right: 0.25rem;
}

.pr-2 {
   padding-right: 0.5rem;
}

.pr-3 {
   padding-right: 1rem;
}

.pr-4 {
   padding-right: 1.5rem;
}

.pr-5 {
   padding-right: 2.5rem;
}

.pt-1 {
   padding-top: 0.25rem;
}

.pt-2 {
   padding-top: 0.5rem;
}

.pt-3 {
   padding-top: 1rem;
}

.pt-4 {
   padding-top: 1.5rem;
}

.pt-5 {
   padding-top: 2.5rem;
}

.pb-1 {
   padding-bottom: 0.25rem;
}

.pb-2 {
   padding-bottom: 0.5rem;
}

.pb-3 {
   padding-bottom: 1rem;
}

.pb-4 {
   padding-bottom: 1.5rem;
}

.pb-5 {
   padding-bottom: 2.5rem;
}

.px-1 {
   padding: 0.25rem 0;
}

.px-2 {
   padding: 0.5rem 0;
}

.px-3 {
   padding: 1rem 0;
}

.px-4 {
   padding: 1.5rem 0;
}

.px-5 {
   padding: 2.5rem 0;
}

.py-1 {
   padding: 0 0.25rem;
}

.py-2 {
   padding: 0 0.5rem;
}

.py-3 {
   padding: 0 1rem;
}

.py-4 {
   padding: 0 1.5rem;
}

.py-5 {
   padding: 0 2.5rem;
}

.m-0 {
    margin: 0;
}

.m-1 {
   margin: 0.25rem;
}

.m-2 {
   margin: 0.5rem;
}

.m-3 {
   margin: 1rem;
}

.m-4 {
   margin: 1.5rem;
}

.m-5 {
   margin: 2.5rem;
}

.ml-1 {
   margin-left: 0.25rem;
}

.ml-2 {
   margin-left: 0.5rem;
}

.ml-3 {
   margin-left: 1rem;
}

.ml-4 {
   margin-left: 1.5rem;
}

.ml-5 {
   margin-left: 2.5rem;
}

.mr-1 {
   margin-right: 0.25rem;
}

.mr-2 {
   margin-right: 0.5rem;
}

.mr-3 {
   margin-right: 1rem;
}

.mr-4 {
   margin-right: 1.5rem;
}

.mr-5 {
   margin-right: 2.5rem;
}

.mt-1 {
   margin-top: 0.25rem;
}

.mt-2 {
   margin-top: 0.5rem;
}

.mt-3 {
   margin-top: 1rem;
}

.mt-4 {
   margin-top: 1.5rem;
}

.mt-5 {
   margin-top: 2.5rem;
}

.mb-1 {
   margin-bottom: 0.25rem;
}

.mb-2 {
   margin-bottom: 0.5rem;
}

.mb-3 {
   margin-bottom: 1rem;
}

.mb-4 {
   margin-bottom: 1.5rem;
}

.mb-5 {
   margin-bottom: 2.5rem;
}

.mx-1 {
   margin-left: 0.25rem;
   margin-right: 0.25rem;
}

.mx-2 {
   margin-left: 0.5rem;
   margin-right: 0.5rem;
}

.mx-3 {
   margin-left: 1rem;
   margin-right: 1rem;
}

.mx-4 {
   margin-left: 1.5rem;
   margin-right: 1.5rem;
}

.mx-5 {
   margin-left: 2.5rem;
   margin-right: 2.5rem;
}

.my-1 {
   margin-top: 0.25rem;
   margin-bottom: 0.25rem;
}

.my-2 {
   margin-top: 0.5rem;
   margin-bottom: 0.5rem;
}

.my-3 {
   margin-top: 1rem;
   margin-bottom: 1rem;
}

.my-4 {
   margin-top: 1.5rem;
   margin-bottom: 1.5rem;
}

.my-5 {
   margin-top: 2.5rem;
   margin-bottom: 2.5rem;
}

.text-center {
   text-align: center;
}

.text-justified {
   text-align: justify;
}

.w-100 {
   width: 100%;
}

.w-90 {
   width: 90%;
}

.w-75 {
   width: 75%;
}