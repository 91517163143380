.goodlookeros {
    background-color: #DDDBD1;
}

.contain {
    min-height: 400px;
}

.title {
    font-family: "AthelasItalic";
    font-size: 1rem;
    font-weight: 700;
    font-size: 1.4rem;
}

.goodlookeros_title {
    font-family: "AthelasItalic";
    color: #866F5E;
    font-weight: 700;
    font-size: 1.4rem;
}

@media (min-width: 576px) {
    .contain {
        min-height: 400px;
    }
}

@media (min-width: 768px) {
    .contain {
        min-height: 450px;
    }
}

@media (min-width: 992px) {
    .contain {
        min-height: 500px;
    }
}

@media (min-width: 1200px) {
    .contain {
        min-height: 500px;
    }
}

@media (min-width: 1400px) {
    .contain {
        min-height: 500px;
    }
}