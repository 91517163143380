.our_professionals {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #E6D8CF; 
    padding-block: 30px; 
    text-align: center;
    min-height: 550px;
}

.professionals_img {
    /* background-repeat: no-repeat; 
    background-size: cover; 
    margin-inline: auto; */
    width: 95%;
    height: 310px;
    object-fit: cover;
    border-radius: 15px;
}

.professionals_img:hover {
    box-shadow: 5px 5px 2.5px rgba(0, 0, 0, 0.5);
}

.carousel {
    height: 350px;
}

@media (min-width: 576px) {
    .our_professionals {
        min-height: 500px;
    }

    .professionals_img {
        height: 310px;
        width: 95%;
    }

    .carousel {
        height: 350px;
    }
}

@media (min-width: 768px) {
    .our_professionals {
        min-height: 600px;
    }

    .professionals_img {
        height: 400px;
        width: 95%;
    }

    .carousel {
        height: 490px;
    }
}

@media (min-width: 992px) {
    .our_professionals {
        min-height: 700px;
    }

    .professionals_img {
        height: 350px;
        width: 95%;
    }

    .carousel {
        height: 590px;
    }
}

@media (min-width: 1200px) {
    .our_professionals {
        min-height: 700px;
    }

    .professionals_img {
        height: 450px;
        width: 95%;
    }

    .carousel {
        height: 640px;
    }
}

@media (min-width: 1400px) {
    .our_professionals {
        min-height: 800px;
    }

    .professionals_img {
        height: 450px;
        width: 95%;
    }

    .carousel {
        height: 640px;
    }
}