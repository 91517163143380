.select_locale {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 42px;
    max-width: 100%;
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 3px;
    padding-inline: 8px;
    margin-bottom: 15px;

}

.flags {
    width: 90%;
}

.flags p {
    text-align: left;
    margin: 0;
}

.flags span {
    margin-right: 8px;
}

.header_mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_web {
    display: none;
}

@media (min-width: 576px) {
    .header_mobile {
        display: flex;
    }

    .header_web {
        display: none;
    }
}

@media (min-width: 768px) {
    .header_mobile {
        display: flex;
    }

    .header_web {
        display: none;
    }
}

@media (min-width: 992px) {
    .header_mobile {
        display: none;
    }

    .header_web {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media (min-width: 1200px) {
    .header_mobile {
        display: none;
    }

    .header_web {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media (min-width: 1400px) {
    .header_mobile {
        display: none;
    }

    .header_web {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}