.service_detail {
    background-color: #FFFFFF;
}

.service_detail_row {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 3px;
    margin-inline: 10px;
}

@media (min-width: 576px) {
    .service_detail_row {
        margin-inline: 10px;
    }
}

@media (min-width: 768px) {
    .service_detail_row {
        margin-inline: 10px;
    }
}

@media (min-width: 992px) {
    .service_detail_row {
        margin-inline: 0;
    }
}

@media (min-width: 1200px) {
    .service_detail_row {
        margin-inline: 0;
    }
}

@media (min-width: 1400px) {
    .service_detail_row {
        margin-inline: 0;
    }
}