.title {
    font-family: "AthelasItalic";
    font-size: 1rem;
    font-weight: 700;
    font-size: 1.4rem;
}

.customers_title {
    font-family: "AthelasItalic";
    color: #866F5E;
    font-weight: 700;
    font-size: 1.4rem;
}