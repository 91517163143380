@import url(./styles/common.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: "Gotham", sans-serif;
  font-weight: 500;
}

li {
  font-family: "Gotham", sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  margin-bottom: 10px;
}

a {
  font-family: 'Gotham', sans-serif;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 0.8rem;
}

a {
  color: #866F5E;
}

img {
  object-fit: contain;
}

button span {
  font-family: "Gotham", sans-serif;
}

section.ant-layout {
  min-height: 100vh;
}

input, .ant-select-selector, .ant-input-password, .ant-picker, .ant-input-affix-wrapper-lg, .MuiInputBase-input.MuiOutlinedInput-input {
  border-radius: 3px !important;
}

.ant-layout-header {
  padding-inline: 0 !important;
}

.ant-layout-header {
  width: 100%;
  padding-right: .75rem !important;
  padding-left: .75rem !important;
}

.slick-dots li button {
  border: 0.5px solid black !important;
}

.react-multiple-carousel__arrow {
  z-index: 95 !important;
}

.form-input {
  margin-bottom: 15px;
}

.span-form {
  display: block;
  width: 100%;
  font-size: 12px;
  text-align: left !important;
  margin-bottom: 0;
}

.span-form.success {
  color: #29CA26;
}

.span-form.default {
  color: #ACACAC;
}

.span-form.error {
  color: #F32B2B;
}

.span-form.info {
  color: #FEF236;
}

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  font-size: 16px;
}

.StripeElement:hover {
  border-color: #94887f;
  border-inline-end-width: 1px;
}

.StripeElement--focus,
.StripeElement:focus,
.StripeElement:focus-visible {
  box-shadow: 0 1px 3px 0 #cfd7df;
  border: 1px solid transparent;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.card-errors{
  color:  #fa755a;
}
