.footer {
    height: max-content;
}

.col_footer_contact {
    text-align: center;
    border: none;
    padding: 0;
}

.col_footer_logo {
    text-align: center;
    border: none;
}

.col_footer_schedule {
    text-align: center;
}

.social_btn {
    width: 50px !important;
    height: 50px !important;
}

@media (min-width: 576px) {
    .col_footer_contact {
        text-align: center;
        border: none;
        padding: 0;
    }

    .col_footer_logo {
        border: none;
    }

    .col_footer_schedule {
        text-align: center;
    }
}
  
@media (min-width: 768px) {
    .col_footer_contact {
        text-align: center;
        border: none;
        padding: 0;
    }

    .col_footer_logo {
        border: none;
    }

    .col_footer_schedule {
        text-align: center;
    }
}
  
@media (min-width: 992px) {
    .col_footer_contact {
        text-align: right;
        border-right: 0.748437px solid #D5D5D5;
        padding-right: 20px !important;
    }

    .col_footer_logo {
        border-right: 0.748437px solid #D5D5D5;
    }

    .col_footer_schedule {
        text-align: left;
        padding-left: 20px !important;
    }
}
  
@media (min-width: 1200px) {
    .col_footer_contact {
        text-align: right;
        border-right: 0.748437px solid #D5D5D5;
        padding-right: 20px !important;
    }

    .col_footer_logo {
        border-right: 0.748437px solid #D5D5D5;
    }

    .col_footer_schedule {
        text-align: left;
        padding-left: 20px !important;
    }
}
  
@media (min-width: 1440px) {
    .col_footer_contact {
        text-align: right;
        border-right: 0.748437px solid #D5D5D5;
        padding-right: 20px !important;
    }

    .col_footer_logo {
        border-right: 0.748437px solid #D5D5D5;
    }

    .col_footer_schedule {
        text-align: left;
        padding-left: 20px !important;
    }
}