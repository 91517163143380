.auth_card {
    margin: 20px;
    padding: 24px;
    text-align: center;
}

.forgot_pass_container {
    text-align: right;
    width: 100%;
    position: relative;
    top: -18px;
}

.forgot_pass_text {
    font-weight: 500;
    color: #866F5E;
}

.select_locale {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 42px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D5D5D5;
    border-radius: 3px;
    padding-inline: 15px;
    margin-block: 15px;
}

.flags {
    width: 90%;
}

.flags p {
    margin: 0;
    text-align: left;
}

.flags span {
    margin-right: 8px;
}