.services {
    background-color: #FFFFFF;
}

.services h2 span {
    font-family: 'Athelas', sans-serif;
    font-style: italic;
}

.services_premium_type {
    color: #866f5e;
    background-color: transparent;
}

.services_standar_type {
    background-color: #866f5e;
    color: #FFFFFF;
}

.services_premium_type,
.services_standar_type {
    border: 1px solid #866f5e;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    height: 40px;
    font-family: 'Gotham', sans-serif;
    font-weight: 500;
}