.step_col {
    padding-block: 20px;
    padding-inline: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-align: left;
}

.title {
    font-family: "AthelasItalic";
    font-size: 1rem;
    font-weight: 700;
    font-size: 1.4rem;
}

.step_title {
    font-family: "AthelasItalic";
    color: #866F5E;
    font-weight: 700;
    font-size: 1.4rem;
}

.step_col button {
    width: 80%;
    margin-inline: 10%;
}

@media (min-width: 768px) {
    .step_col button {
        width: 60%;
        margin-inline: 20%;
    }
}
  
 @media (min-width: 992px) {
    .step_col {
        padding-block: 10px;
        padding-inline: 35px;
    }

    .step_col button {
        width: 60%;
        margin-inline: 20%;
    }
}
  
 @media (min-width: 1200px) {
    .step_col {
        padding-block: 10px;
        padding-inline: 40px;
    }
    .step_col button {
        width: 60%;
        margin-inline: 20%;
    }
}
  
 @media (min-width: 1400px) {
    .step_col {
        padding-block: 10px;
        padding-inline: 60px;
    }

    .step_col button {
        width: 60%;
        margin-inline: 20%;
    }
}